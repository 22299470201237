import PermissoesPadrao from '../constants/enums/PermissoesPadrao';
import Role from '../constants/enums/Role';
import UsuariosClinica from '../constants/UsuariosClinica';
import UsuariosClinicaCompleto from '../constants/UsuariosClinicaCompleto';
import UsuariosClinicaRevisao from '../constants/UsuariosClinicaRevisao';
import UsuariosEmpregadores from '../constants/UsuariosEmpregadores';
import UsuariosRevisao from '../constants/UsuariosRevisao';
import AutenticacaoModule from '../store/modules/AutenticacaoModule';

export default class Permissions {
  /**
   * Verifica se o usuário logado possui a permissão passada.
   *
   * @param {string} permissao - Permissão a ser verificada
   * @returns True se o usuário tiver a permissão e False se não tiver.
   *
   * @remarks Por padrão é usado o enum {@link PermissoesPadrao}.
   */
  public static usuarioTemPermissao(permissao: PermissoesPadrao | string): boolean {
    return AutenticacaoModule.user.permissions.includes(permissao);
  }

  /**
   * Verifica se o usuário logado é Host.
   *
   * @returns True se o usuário for Host e se não for, False.
   */
  public static usuarioHost(): boolean {
    return AutenticacaoModule.user.role === Role.Host;
  }

  /**
   * Verifica se o usuário logado é do tipo Assistência.
   *
   * @returns True se o usuário é do tipo Assistência e se não for, False.
   */
  public static usuarioAssistencia(): boolean {
    return AutenticacaoModule.user.role === Role.Admin;
  }

  /**
   * Verifica se o usuário logado é uma Clínica.
   *
   * @returns True se o usuário for uma Clínica e se não for, False.
   */
  public static usuarioClinica(): boolean {
    return UsuariosClinica.includes(AutenticacaoModule.user.role);
  }

  /**
   * Verifica se o usuário logado é uma Clínica.
   *
   * @returns True se o usuário for uma Clínica e se não for, False.
   */
  public static usuarioClinicaRevisao(): boolean {
    return UsuariosClinicaRevisao.includes(AutenticacaoModule.user.role);
  }

  /**
 * Verifica se o usuário logado é uma Clínica do tipo completo.
 *
 * @returns True se o usuário for uma Clínica e se não for, False.
 */
  public static usuarioClinicaCompleto(): boolean {
    return UsuariosClinicaCompleto.includes(AutenticacaoModule.user.role);
  }

  /**
   * Verifica se o usuário logado é um Empregador.
   *
   * @returns True se o usuário for um empregador e se não for, False.
   */
  public static usuarioEmpregador(): boolean {
    return UsuariosEmpregadores.includes(AutenticacaoModule.user.role);
  }

  /**
   * Verifica se o usuário logado está no modo revisão.
   *
   * @returns True se o usuário é do tipo Revisão.
   */
  public static usuarioRevisao(): boolean {
    return UsuariosRevisao.includes(AutenticacaoModule.user.role);
  }

  /**
   * Verifica se o usuário logado é do tipo Médico(completo).
   *
   * @returns True se o usuário é do tipo Médico e se não for, False.
   */
  public static usuarioMedico(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaMedico;
  }

  /**
   * Verifica se o usuário logado é do tipo Secretário Revisão(completo).
   *
   * @returns True se o usuário é do tipo Secretário Revisão e se não for, False.
   */
  public static usuarioSecretario(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaSecretario;
  }

  /**
   * Verifica se o usuário logado é do tipo Secretário Revisão(completo).
   *
   * @returns True se o usuário é do tipo Secretário Revisão e se não for, False.
   */
  public static usuarioSecretarioRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaSecretarioRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Enfermeiro (completo).
   *
   * @returns True se o usuário é do tipo Enfermeiro e se não for, False.
   */
  public static usuarioEnfermeiro(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaEnfermeiro;
  }

  /**
   * Verifica se o usuário logado é do tipo Enfermeiro Revisão (completo).
   *
   * @returns True se o usuário é do tipo Enfermeiro Revisão  e se não for, False.
   */
  public static usuarioEnfermeiroRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaEnfermeiroRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Médico Revisão (completo) .
   *
   * @returns True se o usuário é do tipo Médico Revisão e se não for, False.
   */
  public static usuarioMedicoRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaMedicoRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Administrador Revisão (completo) .
   *
   * @returns True se o usuário é do tipo Administrador Revisão e se não for, False.
   */
  public static usuarioAdminCompletoRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaAdminRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Administrador (completo) .
   *
   * @returns True se o usuário é do tipo Administrador e se não for, False.
   */
  public static usuarioAdminCompleto(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaAdmin;
  }

  /**
   * Verifica se o usuário logado é do tipo Tec. Operador (completo) .
   *
   * @returns True se o usuário é do tipo Tec. Operador e se não for, False.
   */
  public static usuarioTecOperador(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaTecOperador;
  }

  /**
   * Verifica se o usuário logado é do tipo Tec. Operador Revisão (completo) .
   *
   * @returns True se o usuário é do tipo Tec. Operador Revisão e se não for, False.
   */
  public static usuarioTecOperadorRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaTecOperadorRevisao;
  }

  /**
   * Verifica se o usuário logado é uma Clinica Gestão Envio Interno.
   *
   * @returns True se o usuário for uma Clinica Gestão Envio Interno e se não for, False.
   */
  public static usuarioClinicaGestaoEnvioInterno(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaGestaoEnvioInterno;
  }

  /**
   * Verifica se o usuário logado é uma Clinica Gestão Envio Externo.
   *
   * @returns True se o usuário for uma Clinica Gestão Envio Externo e se não for, False.
   */
  public static usuarioClinicaApenasGestaoESocial(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaApenasGestaoESocial;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Administrador(completo).
   *
   * @returns True se o usuário for do tipo Empregador Administrador e se não for, False.
   */
  public static usuarioEmpregadorAdmin(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorAdmin;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Operador(completo).
   *
   * @returns True se o usuário é do tipo Empregador Operador e se não for, False.
   */
  public static usuarioEmpregadorOperador(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorOperador;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Operador(completo).
   *
   * @returns True se o usuário é do tipo Empregador Empregador Operador e se não for, False.
   */
  public static usuarioEmpregadorApenasGestaoESocial(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorApenasGestaoESocial;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Envio Externo (revisão).
   *
   * @returns True se o usuário é do tipo Empregador Envio Externo (revisão) e se não for, False.
   */
  public static usuarioEmpregadorApenasGestaoESocialRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorApenasGestaoESocialRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Envio Externo.
   *
   * @returns True se o usuário é do tipo Envio Externo (revisão ou não) e se não for, False.
   */
  public static usuarioEnvioExterno(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorApenasGestaoESocial
    || AutenticacaoModule.user.role === Role.ClinicaApenasGestaoESocial
    || AutenticacaoModule.user.role === Role.EmpregadorApenasGestaoESocialRevisao
    || AutenticacaoModule.user.role === Role.ClinicaApenasGestaoESocialRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Envio Interno.
   *
   * @returns True se o usuário é do tipo Envio Interno (revisão ou não) e se não for, False.
   */
  public static usuarioEnvioInterno(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorGestaoEnvioInterno
    || AutenticacaoModule.user.role === Role.ClinicaGestaoEnvioInterno
    || AutenticacaoModule.user.role === Role.EmpregadorGestaoEnvioInternoRevisao
    || AutenticacaoModule.user.role === Role.ClinicaGestaoEnvioInternoRevisao;
  }

  /**
   * Verifica se o usuário logado é uma Clinica Gestão Envio Interno Revisão.
   *
   * @returns True se o usuário for uma Clinica Gestão Envio Interno Revisão e se não for, False.
   */
  public static usuarioClinicaGestaoEnvioInternoRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaGestaoEnvioInternoRevisao;
  }

  /**
   * Verifica se o usuário logado é uma Clinica Gestão Envio Externo Revisão.
   *
   * @returns True se o usuário for uma Clinica Gestão Envio Externo Revisão e se não for, False.
   */
  public static usuarioClinicaApenasGestaoESocialRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaApenasGestaoESocialRevisao;
  }

  /**
   * Verifica se o usuário logado é qualquer tipo de usuário gestão no modo revisão.
   *
   * @returns True se o usuário for uma Clinica Gestão Revisão e se não for, False.
   */
  public static usuarioGestaoRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.ClinicaApenasGestaoESocialRevisao
      || AutenticacaoModule.user.role === Role.ClinicaGestaoEnvioInternoRevisao
      || AutenticacaoModule.user.role === Role.EmpregadorGestaoEnvioInternoRevisao
      || AutenticacaoModule.user.role === Role.EmpregadorApenasGestaoESocialRevisao;
  }


  /**
   * Verifica se o usuário logado é do tipo Empregador Admin Revisão.
   *
   * @returns True se o usuário é do tipo Empregador Admin Revisão e se não for, False.
   */
  public static usuarioEmpregadorRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Operador Revisão.
   *
   * @returns True se o usuário é do tipo Empregador Operador Revisão e se não for, False.
   */
  public static usuarioEmpregadorOperadorRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorOperadorRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Gestão ESocial Revisão(interno).
   *
   * @returns True se o usuário é do tipo Empregador Gestão Interno Revisão e se não for, False.
   */
  public static usuarioEmpregadorGestaoEnvioInternoRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorGestaoEnvioInternoRevisao;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Gestão ESocial(interno).
   *
   * @returns True se o usuário é do tipo Empregador Gestão Interno e se não for, False.
   */
  public static usuarioEmpregadorGestaoEnvioInterno(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorGestaoEnvioInterno;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Gestão ESocial(externo).
   *
   * @returns True se o usuário é do tipo Empregador Gestão ESocial e se não for, False.
   */
  public static usuarioEmpregadorApenasGestaoEsocial(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorApenasGestaoESocial;
  }

  /**
   * Verifica se o usuário logado é do tipo Empregador Gestão ESocial Revisão(externo).
   *
   * @returns True se o usuário é do tipo Empregador Gestão ESocial Revisão e se não for, False.
   */
  public static usuarioEmpregadorApenasGestaoEsocialRevisao(): boolean {
    return AutenticacaoModule.user.role === Role.EmpregadorApenasGestaoESocialRevisao;
  }

  // Métodos para verificar permissões específicas

  public static PermissaoParaCriarUsuario(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.UsuarioCreate);
  }

  public static PermissaoParaAtualizarUsuario(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.UsuarioUpdate);
  }

  public static PermissaoParaAtualizarPerfil(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.UsuarioUpdatePerfil);
  }

  public static PermissaoParaVisualizarEmpresas(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EmpresaReadAll);
  }

  public static PermissaoParaVisualizarEmpresa(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EmpresaRead);
  }

  public static PermissaoParaCriarEmpresa(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EmpresaCreate);
  }

  public static PermissaoParaEditarEmpresa(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EmpresaUpdate);
  }

  public static PermissaoParaVisualizarSetores(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.SetorReadAll);
  }

  public static PermissaoParaVisualizarSetor(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.SetorRead);
  }

  public static PermissaoParaCriarSetor(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.SetorCreate);
  }

  public static PermissaoParaEditarSetor(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.SetorUpdate);
  }

  public static PermissaoParaVisualizarFuncoes(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FuncaoReadAll);
  }

  public static PermissaoParaVisualizarFuncao(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FuncaoRead);
  }

  public static PermissaoParaCriarFuncao(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FuncaoCreate);
  }

  public static PermissaoParaEditarFuncao(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FuncaoUpdate);
  }

  public static PermissaoParaVisualizarAmbiente(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AmbienteRead);
  }

  public static PermissaoParaEditarAmbiente(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AmbienteUpdate);
  }

  public static PermissaoParaVisualizarTodosOsRiscos(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FatorDeRiscoReadAll);
  }

  public static PermissaoParaVisualizarRisco(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FatorDeRiscoRead);
  }

  public static PermissaoParaCriarRisco(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FatorDeRiscoCreate);
  }

  public static PermissaoParaEditarRisco(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FatorDeRiscoUpdate);
  }

  public static PermissaoParaExcluirRisco(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FatorDeRiscoDelete);
  }

  public static PermissaoParaVisualizarConfiguracaoDoExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConfiguracaoDoExameRead);
  }

  public static PermissaoParaCriarExame(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConfiguracaoDoExameCreate);
  }

  public static PermissaoParaEditarExame(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConfiguracaoDoExameUpdate);
  }

  public static PermissaoParaExcluirExame(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConfiguracaoDoExameDelete);
  }

  public static PermissaoParaCriarRegraOcupacional(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.RegraExameOcupacionalCreate);
  }

  public static PermissaoParaExcluirRegraOcupacional(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.RegraExameOcupacionalDelete);
  }

  public static PermissaoParaCriarRegraPeriodica(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.RegraExamePeriodicoCreate);
  }

  public static PermissaoParaExcluirRegraPeriodica(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.RegraExamePeriodicoDelete);
  }

  public static PermissaoParaCriarPeriodicidade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PeriodicidadeCreate);
  }

  public static PermissaoParaEditarPeriodicidade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PeriodicidadeUpdate);
  }

  public static PermissaoParaExcluirPeriodicidade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PeriodicidadeDelete);
  }

  public static PermissaoParaVisualizarPCMSO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PcmsoRead);
  }

  public static PermissaoParaExcluirPCMSO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PcmsoDelete);
  }

  public static PermissaoParaCriarPCMSO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PcmsoCreate);
  }

  public static PermissaoParaEditarPCMSO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PcmsoUpdate);
  }

  public static PermissaoParaVisualizarTreinamentos(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TreinamentoReadAll);
  }

  public static PermissaoParaCriarTreinamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TreinamentoCreate);
  }

  public static PermissaoParaEditarTreinamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TreinamentoUpdate);
  }

  public static PermissaoParaExcluirTreinamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TreinamentoDelete);
  }

  public static PermissaoParaCriarFuncionario(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FuncionarioCreate);
  }

  public static PermissaoParaEditarFuncionario(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FuncionarioUpdate);
  }

  public static PermissaoParaVisualizarRegistrosProfissionais(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.RegistroProfReadAll);
  }

  public static PermissaoParaCriarRegistroProfissional(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.RegistroProfCreate);
  }

  public static PermissaoParaEditarRegistroProfissional(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.RegistroProfUpdate);
  }

  public static PermissaoParaVisualizarASOs(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AsoReadAll);
  }

  public static PermissaoParaVisualizarASO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AsoRead);
  }

  public static PermissaoParaCriarASO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AsoCreate);
  }

  public static PermissaoParaEditarASO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AsoUpdate);
  }

  public static PermissaoParaExcluirASO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AsoDelete);
  }

  public static PermissaoParaEditarFichaClinica(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FichaClinicaUpdate);
  }

  public static PermissaoParaVisualizarResultado(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.UploadRead);
  }

  public static PermissaoParaVisualizarCATs(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CatReadAll);
  }

  public static PermissaoParaVisualizarCAT(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CatRead);
  }

  public static PermissaoParaCriarCAT(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CatCreate);
  }

  public static PermissaoParaEditarCAT(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CatUpdate);
  }

  public static PermissaoParaVisualizarPrestadores(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PrestadorServReadAll);
  }

  public static PermissaoParaVisualizarPrestador(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PrestadorServRead);
  }

  public static PermissaoParaCriarPrestador(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PrestadorServCreate);
  }

  public static PermissaoParaEditarPrestador(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PrestadorServUpdate);
  }

  public static PermissaoParaCriarEquipamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EquipamentosCreate);
  }

  public static PermissaoParaEditarEquipamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EquipamentosUpdate);
  }

  public static PermissaoParaExcluirEquipamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EquipamentosDelete);
  }

  public static PermissaoParaVisualizarLogs(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AuditoriaRead);
  }

  public static PermissaoParaCriarLoteInternoS2210(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LoteS2210Create);
  }

  public static PermissaoParaCriarLoteInternoS2220(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LoteS2220Create);
  }

  public static PermissaoParaCriarLoteInternoS2221(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LoteS2221Create);
  }

  public static PermissaoParaCriarLoteInternoS2230(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LoteS2230Create);
  }

  public static PermissaoParaCriarLoteInternoS2240(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LoteS2240Create);
  }

  public static PermissaoParaCriarLoteInternoS3000(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LoteS3000Create);
  }

  public static PermissaoParaExcluirLote(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ESocialDelete);
  }

  public static PermissaoParaEnviarLote(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ESocialEnvio);
  }

  public static PermissaoParaVisualizarPGR(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PGRReadAll);
  }

  public static PermissaoParaCriarPGR(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PGRCreate);
  }

  public static PermissaoParaEditarPGR(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PGRUpdate);
  }

  public static PermissaoParaExcluirPGR(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.PGRDelete);
  }

  public static PermissaoParaVisualizarLTCATs(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LtcatReadAll);
  }

  public static PermissaoParaVisualizarLTCAT(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LtcatRead);
  }

  public static PermissaoParaCriarLTCAT(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LtcatUpdate);
  }

  public static PermissaoParaEditarLTCAT(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LtcatUpdate);
  }

  public static PermissaoParaExcluirLTCAT(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LtcatDelete);
  }

  public static PermissaoParaCriarImpresso(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ImpressoCreate);
  }

  public static PermissaoParaCriarBibliografia(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.BibliografiaCreate);
  }

  public static PermissaoParaVisualizarTodosAparelho(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AparelhoReadAll);
  }

  public static PermissaoParaVisualizarAparelho(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AparelhoRead);
  }

  public static PermissaoParaCriarAparelho(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AparelhoCreate);
  }

  public static PermissaoParaEditarAparelho(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AparelhoUpdate);
  }

  public static PermissaoParaDeletarAparelho(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AparelhoDelete);
  }

  public static PermissaoParaCriarCategoria(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CategoriasCreate);
  }

  public static PermissaoParaEditarCategoria(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CategoriasUpdate);
  }

  public static PermissaoParaCriarFabricante(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FabricantesCreate);
  }

  public static PermissaoParaEditarFabricante(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FabricantesUpdate);
  }

  public static PermissaoParaGerarRelatorioDoASO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.GeraRelatorioASO);
  }

  public static PermissaoParaGerarRelatorioDeFuncionarios(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.GeraRelatorioFuncionariosPorEmpresa);
  }

  public static PermissaoParaGerarRelatorioDeConvocacaoPeriodica(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.GeraRelatorioConvocacaoPeriodica);
  }

  public static PermissaoParaReenviarEmail(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EmailReenviaCreate);
  }

  public static PermissaoParaCriarConvenio(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConvenioCreate);
  }

  public static PermissaoParaEditarConvenio(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConvenioUpdate);
  }

  public static PermissaoParaEditarProcedimento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ProcedimentoUpdate);
  }

  public static PermissaoParaExcluirProcedimento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ProcedimentoDelete);
  }

  public static PermissaoParaCriarAgendamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AgendamentoCreate);
  }

  public static PermissaoParaEditarAgendamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AgendamentoUpdate);
  }

  public static PermissaoParaExcluirAgendamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AgendamentoDelete);
  }

  public static PermissaoParaVisualizarBloqueio(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.BloqueioRead);
  }

  public static PermissaoParaCriarBloqueio(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.BloqueioCreate);
  }

  public static PermissaoParaEditarBloqueio(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.BloqueioUpdate);
  }

  public static PermissaoParaExcluirBloqueio(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.BloqueioDelete);
  }

  public static PermissaoParaEditarCertificadoDigitalClinica(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ClinicaCertificadoDigitalUpdate);
  }

  public static PermissaoParaEditarCertificadoDigitalEmpresa(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.EmpresaCertificadoUpdate);
  }

  public static PermissaoParaVisualizarConfiguracaoFaturamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConfiguracaoFaturamentoRead);
  }

  public static PermissaoParaCriarConfiguracaoFaturamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConfiguracaoFaturamentoCreate);
  }

  public static PermissaoParaEnviarFatura(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FaturaPorEmail);
  }

  public static PermissaoParaCriarServico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ServicoFaturavelCreate);
  }

  public static PermissaoParaEditarServico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ServicoFaturavelUpdate);
  }

  public static PermissaoParaExcluirServicoFaturavel(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ServicoFaturavelDelete);
  }

  public static PermissaoParaVisualizarFatura(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.FaturaReadAll);
  }

  public static PermissaoParaGerarImpressoAuditoria(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AuditoriaReadAll);
  }

  public static PermissaoVisualizarTelaDeCBO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CboReadAll);
  }

  public static PermissaoParaCriarUmNovoCBO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CboCreate);
  }

  public static PermissaoParaCriarEditarUmCBO(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.CboUpdate);
  }

  public static PermissaoParaVisualizarAfastamentos(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AfastamentoReadAll);
  }

  public static PermissaoParaVisualizarAfastamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AfastamentoRead);
  }

  public static PermissaoParaCriarAfastamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AfastamentoCreate);
  }

  public static PermissaoParaEditarAfastamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AfastamentoUpdate);
  }

  public static PermissaoParaExcluirAfastamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AfastamentoDelete);
  }

  public static PermissaoParaVisualizarAcompanhamentos(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AcompanhamentoReadAll);
  }

  public static PermissaoParaVisualizarAcompanhamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AcompanhamentoRead);
  }

  public static PermissaoParaCriarAcompanhamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AcompanhamentoCreate);
  }

  public static PermissaoParaEditarAcompanhamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AcompanhamentoUpdate);
  }

  public static PermissaoParaExcluirAcompanhamento(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.AcompanhamentoDelete);
  }

  public static PermissaoParaImportarPlanilha(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ImportarPlanilha);
  }

  public static PermissaoParaVisualizarOrdensDeServico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.OrdemDeServicoReadAll);
  }

  public static PermissaoParaVisualizarOrdemDeServico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.OrdemDeServicoRead);
  }

  public static PermissaoParaCriarOrdemDeServico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.OrdemDeServicoCreate);
  }

  public static PermissaoParaEditarOrdemDeServico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.OrdemDeServicoUpdate);
  }

  public static PermissaoParaExcluirOrdemDeServico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.OrdemDeServicoDelete);
  }

  public static PermissaoParaVisualizarLTIPs(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LTIPReadAll);
  }

  public static PermissaoParaVisualizarLTIP(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LTIPRead);
  }

  public static PermissaoParaCriarLTIP(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LTIPCreate);
  }

  public static PermissaoParaEditarLTIP(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LTIPUpdate);
  }

  public static PermissaoParaExcluirLTIP(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LTIPDelete);
  }

  public static PermissaoParaVisualizarLaudosPericulosidades(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoPericulosidadeReadAll);
  }

  public static PermissaoParaVisualizarLaudoPericulosidade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoPericulosidadeRead);
  }

  public static PermissaoParaCriarLaudoPericulosidade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoPericulosidadeCreate);
  }

  public static PermissaoParaEditarLaudoPericulosidade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoPericulosidadeUpdate);
  }

  public static PermissaoParaExcluirLaudoPericulosidade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoPericulosidadeDelete);
  }

  public static PermissaoParaVisualizarTodosLaudoInsalubridade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoInsalubridadeReadAll);
  }

  public static PermissaoParaVisualizarLaudoInsalubridade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoInsalubridadeRead);
  }

  public static PermissaoParaCriarLaudoInsalubridade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoInsalubridadeCreate);
  }

  public static PermissaoParaEditarLaudoInsalubridade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoInsalubridadeUpdate);
  }

  public static PermissaoParaExcluirLaudoInsalubridade(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.LaudoInsalubridadeDelete);
  }

  public static PermissaoParaVisualizarTodosExamesPersonalizados(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesPersonalizadosReadAll);
  }

  public static PermissaoParaVisualizarExamesPersonalizados(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesPersonalizadosRead);
  }

  public static PermissaoParaCriarExamesPersonalizados(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesPersonalizadosCreate);
  }

  public static PermissaoParaEditarExamesPersonalizados(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesPersonalizadosUpdate);
  }

  public static PermissaoParaExcluirExamesPersonalizados(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesPersonalizadosDelete);
  }

  public static PermissaoParaVisualizarTodosExamesDaFuncao(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExameDaFuncaoReadAll);
  }

  public static PermissaoParaVisualizarExamesDaFuncao(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExameDaFuncaoRead);
  }

  public static PermissaoParaCriarExamesDaFuncao(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExameDaFuncaoCreate);
  }

  public static PermissaoParaEditarExamesDaFuncao(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExameDaFuncaoUpdate);
  }

  public static PermissaoParaExcluirExamesDaFuncao(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExameDaFuncaoDelete);
  }

  public static PermissaoParaVisualizarTodosKitDeExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.KitDeExamesReadAll);
  }

  public static PermissaoParaVisualizarKitDeExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.KitDeExamesRead);
  }

  public static PermissaoParaCriarKitDeExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.KitDeExamesCreate);
  }

  public static PermissaoParaEditarKitDeExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.KitDeExamesUpdate);
  }

  public static PermissaoParaExcluirKitDeExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.KitDeExamesDelete);
  }

  public static PermissaoParaVisualizarTodosTiposDeExamesPersonalizados(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TipoDeExamePersonalizadoReadAll);
  }

  public static PermissaoParaVisualizarTipoDeExamePersonalizado(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TipoDeExamePersonalizadoRead);
  }

  public static PermissaoParaCriarTipoDeExamePersonalizado(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TipoDeExamePersonalizadoCreate);
  }

  public static PermissaoParaEditarTipoDeExamePersonalizado(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TipoDeExamePersonalizadoUpdate);
  }

  public static PermissaoParaExcluirTipoDeExamePersonalizado(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.TipoDeExamePersonalizadoDelete);
  }

  public static PermissaoParaVisualizarTodosExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExameReadAll);
  }

  public static PermissaoParaCriarExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesCreate);
  }

  public static PermissaoParaEditarExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesUpdate);
  }

  public static PermissaoParaExcluirExames(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesDelete);
  }

  public static PermissaoParaCriarConfiguracoesGerais(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ConfiguracoesGeraisCreate);
  }

  public static PermissaoParaVisualizarExamesToxicologicos(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesToxicologicosReadAll);
  }

  public static PermissaoParaVisualizarExameToxicologico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesToxicologicosRead);
  }

  public static PermissaoParaCriarExameToxicologico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesToxicologicosCreate);
  }

  public static PermissaoParaEditarExameToxicologico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesToxicologicosUpdate);
  }

  public static PermissaoParaExcluirExameToxicologico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesToxicologicosDelete);
  }

  public static PermissaoParaImprimirExameToxicologico(): boolean {
    return this.usuarioTemPermissao(PermissoesPadrao.ExamesToxicologicosImpresso);
  }
}

