import Vue from 'vue';
import Vuex from 'vuex';

import { IAfastamentoState } from './modules/AfastamentoModule';
import { IAmbienceState } from './modules/AmbienteModule';
import { IAtividadesInsalubresModule } from './modules/AtividadesInsalubresModule';
import { IAuthState } from './modules/AutenticacaoModule';
import { ICategoryState } from './modules/CategoriaModule';
import { ICatState } from './modules/CatModule';
import { ICboState } from './modules/CboModule';
import { IClinicState } from './modules/ClinicaModule';
import { ICnaeState } from './modules/CnaeModule';
import { IIBGECodeState } from './modules/CodigoIbgeModule';
import { IConfiguracaoFaturamentoState } from './modules/ConfiguracaoFaturamentoModule';
import { IConvenioState } from './modules/ConvenioModule';
import { ICompanyState } from './modules/EmpresaModule';
import { IEquipamentState } from './modules/EquipamentoModule';
import { IExameState } from './modules/ExameModule';
import { IExameToxicologicoState } from './modules/ExameToxicologicoModule';
import { IMakerState } from './modules/FabricanteModule';
import { IFaturaState } from './modules/FaturaModule';
import { IFichaClinicaState } from './modules/FichaClinicaModule';
import { IFunctionState } from './modules/FuncaoModule';
import { IEmployeeState } from './modules/FuncionarioModule';
import { ILayoutState } from './modules/LayoutModule';
import { ILtipState } from './modules/LtipModule';
import { IMarcadorPersonalizadoState } from './modules/MarcadorPersonalizadoModule';
import { IMotivoDeAfastamentoState } from './modules/MotivoDeAfastamentoModule';
import { IOrdemDeServicoState } from './modules/OrdemDeServicoModule';
import { ICountryState } from './modules/PaisModule';
import { IPcmsoState } from './modules/PcmsoModule';
import { IServiceProviderState } from './modules/PrestadorDeServicoModule';
import { IProcedimentoState } from './modules/ProcedimentoModule';
import { IProfessionalRecordState } from './modules/RegistroProfissionalModule';
import { IRiscoPersonalizadoState } from './modules/RiscoPersonalizadoModule';
import { ICatConfigImpressoState } from './modules/CatConfigImpressoModule';
import { IDepartmentState } from './modules/SetorModule';
import { IESocialTablesState } from './modules/TabelasESocialModules';
import { ITypeOfStreetState } from './modules/TipoDeLogradouroModule';
import { IUserState } from './modules/UsuarioModule';

Vue.use(Vuex);

export interface IRootState {
  afastamento: IAfastamentoState;
  ambience: IAmbienceState;
  auth: IAuthState;
  cat: ICatState;
  CatConfigImpresso: ICatConfigImpressoState;
  category: ICategoryState;
  cbo: ICboState;
  clinic: IClinicState;
  cnae: ICnaeState;
  company: ICompanyState;
  configuracaoFaturamento: IConfiguracaoFaturamentoState;
  convenio: IConvenioState;
  country: ICountryState;
  department: IDepartmentState;
  employee: IEmployeeState;
  equipament: IEquipamentState;
  esocialTables: IESocialTablesState;
  exam: IExameState;
  exameToxicologico: IExameToxicologicoState;
  fatura: IFaturaState;
  fichaClinica: IFichaClinicaState;
  function: IFunctionState;
  ibgeCode: IIBGECodeState;
  layout: ILayoutState;
  ltip: ILtipState;
  maker: IMakerState;
  marcadorPersonalizado: IMarcadorPersonalizadoState;
  motivoDeAfastamento: IMotivoDeAfastamentoState;
  ordensDeServico: IOrdemDeServicoState
  pcmso: IPcmsoState;
  procedimento: IProcedimentoState;
  professionalRecord: IProfessionalRecordState;
  riscoPersonalizado: IRiscoPersonalizadoState;
  serviceProvider: IServiceProviderState;
  typeOfStreet: ITypeOfStreetState;
  atividadesInsalubres: IAtividadesInsalubresModule;
  user: IUserState;
  changeValues: Boolean;
  isNotLogout: Boolean;
}

export default new Vuex.Store<IRootState>({});
