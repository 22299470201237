<template>
  <validation-provider
    :ref="`datepicker-${name}`"
    :vid="name"
    :name="label"
    :rules="rules"
    :disabled="disabled"
    v-slot="{ errors, valid, validated }"
  >
    <b-form-group :label="label ? `${label} ${required ? '*' : ''}` : null">
      <div class="date--input">
        <v-date-picker
          mode="single"
          color="green"
          :value="dateValue"
          :attributes="attributes"
          :is-required="required"
          :popover="popoverConfiguration"
          :min-date="minDate"
          :max-date="maxDate"
          :disabled-dates="disabledDates"
          @input="input"
        >
            <b-form-input
              type="text"
              :value="stringValue"
              :placeholder="placeholder"
              autocomplete="off"
              :disabled="disabled"
              :state="(rules.length && validated) ? valid : null"
              v-mask="mask"
              @input="input"
            />
        </v-date-picker>
        </div>
      <p
        v-show="validated && !valid"
        class="error-container text-danger small"
      >
        {{ errors[0] }}
      </p>
    </b-form-group>
  </validation-provider>
</template>

<script lang="ts">
import moment from 'moment';
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import MaskHelper from '../../helpers/MaskHelper';
import Notification from '../../helpers/NotificationHelper';
import ComponenteBase from '../ComponenteBase.vue';

@Component
class DatePicker extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public label!: string;

  @Prop({ type: String, default: '' })
  public name!: string;

  @Prop({ type: String, default: null })
  private value!: string;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  public required!: boolean;

  @Prop({ type: Date, default: null })
  public minDate!: Date;

  @Prop({ type: Date, default: null })
  public maxDate!: Date;

  @Prop({ type: Object, default: null })
  public disabledDates!: object;

  @Prop({ type: String, default: 'bottom-start' })
  private placement!: string;

  @Prop({ type: Boolean, default: false })
  private fixed!: boolean;

  @Prop({ type: String, default: MaskHelper.date })
  public mask!: string;

  @Prop({ type: String, default: 'dd/mm/aaaa' })
  public placeholder!: string;

  @Prop({ type: Boolean, default: false })
  private showWeekdays!: boolean;

  dateValue: Date | null = null!;

  stringValue: string = '';

  mounted() {
    this.changeValue();
  }

  get validator() {
    return this.$refs[`datepicker-${this.name}`] as any;
  }

  get rules() {
    return this.required ? 'required' : '';
  }

  get masks() {
    return MaskHelper;
  }

  get attributes() {
    return [
      {
        key: 'today',
        dot: true,
        dates: new Date(),
      },
    ];
  }

  get popoverConfiguration() {
    return {
      visibility: 'click',
      placement: this.placement,
      positionFixed: this.fixed,
    };
  }

  input(input: string | Date) {
    if (((input as String).length === 5) && (this.mask === '##/##')) {
      // eslint-disable-next-line
      input += ('/' +  moment().format('YYYY'));
    } else if (((input as String).length === 2) && (this.mask === '##')) {
      // eslint-disable-next-line
      input += ('/' +  moment().format('MM/YYYY'));
    }
    const value = input instanceof Date ? moment(input).format('DD/MM/YYYY') : input;

    if (value.length === 10) this.dateValue = moment(value, 'DD/MM/YYYY').toDate();
    else this.dateValue = null!;
    this.validator.validate();
    this.$emit('input', this.dateValue);
    this.$emit('change', { value: this.dateValue ? this.dateValue.toJSON() : null, field: this.name });
  }

  @Watch('disabled')
  private changeDisabled() {
    (this.$refs[`datepicker-${this.name}`] as any).reset();
  }

  @Watch('value')
  private changeValue() {
    const valueMoment = moment(this.value);
    if (!valueMoment.isValid()) {
      this.stringValue = '';
      this.dateValue = null!;
      return;
    }
    this.dateValue = valueMoment.toDate();
    this.stringValue = valueMoment.format('DD/MM/YYYY');
  }

  private clear() {
    this.dateValue = null;
  }
}

export default DatePicker;
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.error-container {
  position: absolute;
  top: 96%;
}

.date--input {
  position: relative;

  .form-control {
    padding-right: 15px;

    &.is-valid {
      background-image: none;
    }

    &.is-invalid {
      background-image: none;
    }
  }

  .form-control[readonly]:not([disabled]) {
    background-color: #ffffff;
  }

  .clear-button {
    position: absolute;
    top: 10px;
    right: 5px;
    opacity: .4;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
