<template>
  <div :class="{'section-divider': sectionDividerPicker }">
        <h4 v-if="title" :class="{'section-title': sectionDividerPicker }">{{ title }}&nbsp;&nbsp;
          <i v-if="showInfo" class="h5 text-primary text-left fas fa-circle-info" v-b-popover.hover="{
                             customClass: 'popoveer',
                             title: 'Informação',
                             content: textoInfo,
                             boundary: 'viewport',
                             placement: 'top'
          }"/>
        </h4>
    <b-row :class="{ 'mt-2': sectionDividerPicker }">
      <b-col>
        <soi-picker
          :name="name"
          :label="label"
          :title="title"
          :placeholder="placeholder"
          :multiselect="multiselect"
          :pagedTable="pagedTable"
          :value="value"
          :modalFields="modalFields"
          :modalItems="modalItems"
          :disabled="disabled"
          :loading="loading"
          @change="$emit('change', $event)"
          @fetchData="$emit('fetchData', $event)"
          :hasSortIcon="hasSortIcon"
          @sortData="$emit('sortData', $event)"
          :colunasSemOrdenacaoExterna="colunasSemOrdenacaoExterna"
        >
          <template v-for="{ key } in modalFields.filter(({ isTemplate }) => isTemplate)" #[key]="{ linha: { item } }">
            <slot :name="key" :valor="item"/>
          </template>
        </soi-picker>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-4">
        <div v-if="itensAListar.length" :class="{ 'section-divider': sectionDividerList }">
          <h4 class="section-title mt-3 mb-4"><b>{{ tituloListagem }}</b></h4>
          <b-list-group v-for="(item, index) in itensAListar" :key="index">
            <b-list-group-item class="d-flex justify-content-between mb-2">
              <div class="d-flex flex-column w-100 pr-4">
                <small >
                  {{ exibirItem(item) }}
                </small>
              </div>
              <soi-button
                class="text-danger"
                title="Remover"
                icon="trash-alt"
                variant="link"
                :disabled="disabledExclusao || permissions.usuarioEmpregador()"
                @click="excluirItem(item[chave])"
              />
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';

import ITableField from '../../models/Interfaces/ITableField';
import { IPagedResult } from '../../models/Paginacao/PagedResult';
import ComponenteBase from '../ComponenteBase.vue';

@Component
class PickerList extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public label!: string;

  @Prop({ type: String, default: '' })
  public name!: string;

  @Prop({ type: String, default: '' })
  public value!: string;

  @Prop({ type: String, default: '' })
  public title!: string;

  @Prop({ type: Boolean, default: false })
  public multiselect!: boolean;

  @Prop({ type: Boolean, default: false })
  public pagedTable!: boolean;

  @Prop({ type: String, default: '' })
  public placeholder!: string;

  @Prop({ type: Boolean, default: false })
  public disabled!: string;

  @Prop({ type: Boolean, default: false })
  public disabledExclusao!: string;

  @Prop({ type: Boolean, default: false })
  public loading!: boolean;

  @Prop({ type: Array, default: () => [] })
  public modalFields!: Array<ITableField>;

  @Prop({ default: () => [] })
  public modalItems!: Array<any> | IPagedResult;

  @Prop({ type: String, default: 'id' })
  public chave!: string;

  @Prop({ type: Array, default: () => [] })
  public itensAListar!: Array<any>;

  @Prop({ type: Array, default: [] })
  public propriedadesAListar!: Array<string>;

  @Prop({ type: Boolean, default: false })
  public showInfo!: boolean;

  @Prop({ type: String, default: '' })
  public textoInfo!: string;

  // Ativa a section divider em volta do componente.
  @Prop({ type: Boolean, default: true })
  public sectionDividerPicker!: boolean;

  // Ativa a section divider em volta dos prestadores selecionados.
  @Prop({ type: Boolean, default: false })
  public sectionDividerList!: boolean;

  // Adiciona um titulo no section divider dos prestadores selecionados. Por padrão, adiciona 'Prestadores de Serviço'.
  @Prop({ type: String, default: 'Prestadores de Serviço' })
  public tituloListagem!: string;

  @Prop({ type: Boolean, default: false })
  public hasSortIcon!: boolean;

  @Prop({ type: Array, default: () => [] })
  public colunasSemOrdenacaoExterna!: string[];



  public exibirItem(item: any) {
    const atributos = this.propriedadesAListar.map((propriedade) => item[propriedade]);
    return atributos.join(' - ');
  }

  public excluirItem(itemId: number | undefined) {
    const listaAtualizada = this.itensAListar.filter((item) => item[this.chave] !== itemId);
    this.$emit('aoRemover', listaAtualizada);
  }
} export default PickerList;
</script>
